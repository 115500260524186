import React from 'react'
import { withProduct } from '../withProduct'
import { DefaultDateRenderer as DateRenderer } from 'components/shared/Renderer'
import { DefaultHTMLRenderer } from 'components/shared/Renderer/Content'
import { HTMLRenderer as ExcerptRenderer } from 'components/shared/Excerpt'
import { DefaultTitleRenderer as TitleRenderer } from 'components/shared/Renderer'
import { DefaultImageRenderer as ImageRenderer } from 'components/shared/Renderer'

/**
 *
 */
export const Title = withProduct(
  ({ product, className, render: Renderer = TitleRenderer, ...props }) => {
    return <Renderer value={product.title} className={className} {...props} />
  }
)

/**
 *
 */
export const SliceTitle = withProduct(
  ({ product, render: Renderer = TitleRenderer, ...props }) => {
    if (product.title.length >= 53) {
      const sliceTitle = product.title.slice(0, 53) + '...'
      return <Renderer value={sliceTitle} {...props} />
    }
    return <Renderer value={product.title} {...props} />
  }
)

/**
 *
 */
export const Content = withProduct(
  ({ product, render: Renderer = DefaultHTMLRenderer, ...props }) => {
    if (product === undefined) {
      return null
    }

    return <Renderer value={product.content} {...props} />
  }
)

/**
 *
 */
export const Date = withProduct(
  ({ product, render: Renderer = DateRenderer, ...props }) => {
    return <Renderer value={product.date} {...props} />
  }
)

/**
 *
 */
export const Excerpt = withProduct(
  ({ product, render: Renderer = ExcerptRenderer, ...props }) => {
    return <Renderer value={product.excerpt} {...props} />
  }
)

/**
 *
 */
export const Image = withProduct(
  ({ product, render: Renderer = ImageRenderer, ...props }) => {
    const Image =
      product.featured_media === null
        ? 'No,Image'
        : product.featured_media.localFile
    // console.log(Image, product.source_url)

    return <Renderer value={Image} alt="thumbnail" {...props} />
  }
)
